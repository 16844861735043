import React from 'react';
import { Link,navigate } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import { ArrowLeft } from '@components/icon/icon';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';

const generalEnq= (officedata) => {
    typeof window !== "undefined" && window.localStorage.setItem('office_details', (JSON.stringify({ officeinfo: officedata})))
    navigate('/contact/general-enquiry/?contact=officecontact')
}

const PropertyTop = (props) => {

    const handleBack = () => window.history.back();

    return (
        <div className="property-top">
            <Container>
                <Breadcrumbs detailTitle={props.data.Title} alias="our-offices"/>
                <Row className="justify-content-between property-row">
                    <Col lg="7">
                        <h1>
                            {props.data.Title}
                        </h1>
                        <p>{props.data.Address}</p>
                    </Col>
                    <Col lg="3">
                        <Link to={`/contact/general-enquiry/?office=${props.data.URL}`} className="btn d-none d-lg-block">Contact Office</Link>
                    </Col>
                </Row>
                <Row  className="justify-content-between property-row office-top">
                    <Col lg="8">
                        <Row>
                            <Col lg="3" md="6">
                                <h6>Phone Number:</h6>
                                <h5><a href={`tel:${props.data.Phone_Number}`}>{props.data.Phone_Number}</a></h5>
                            </Col>
                            <Col lg="7" md="6">
                                <h6>Email:</h6>
                                <h5><a href={`mailto:${props.data.Email}`}>{props.data.Email}</a></h5>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="3" className="d-none d-lg-block">
                        <h6>Looking to Sell or Let?</h6>
                        <h6><span><a href="/value-my-property/property-valuation/">Book a Property Valuation</a></span></h6>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PropertyTop
