import React from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "aos/dist/aos.css";
import get from 'lodash/get'
import OfficeTop from "@components/office-top/office-top";
import FeaturedProperty from "@components/featured-property/featured-property";
import HomeContact from "@components/home-contact/home-contact";
import Valuation from "@components/valuation/valuation";
import Wave from "@components/wave/wave";
import HomeTextColumns from "@components/home-text-columns/home-text-columns";
import TeamCarousel from "@components/office-team-slider/office-team-slider";
import HomeRowImgText from "@components/home-row-img-text/home-row-img-text";
import Officemap from "@components/office-location/office-location";
import axios from 'axios';
import { graphql } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "gatsby";



class OfficeTemplate extends React.Component {

  render() {
    //Detail pages match
    //Default fetch

    const Page = get(this, 'props.data.strapiOffices')
    const Office = get(this, 'props.data.strapiOffices')
    const GlobalConfig = get(this, 'props.data.strapiGlobalConfig')
    const Modules = get(this, 'props.data.strapiOffices.Modules')
    const TeamMember = get(this, 'props.data.strapiOffices.Team_Members')
    const TeamSlider = get(this, 'props.data.strapiOffices.Slider_Team_Members')

    return (
      <>
        <SEO title={Office.Meta_Title} description={Office.Meta_Description} />
        <Layout guides={Page.Select_Areaguide} area={Page.URL} popularSearch="staticdetails" footerDetails={GlobalConfig}>
          <div className="property-wrapper office-details">
            <OfficeTop data={Office} />
            {Office.Banner_Image != null &&
              <Wave Title={Office.Title+' Banner'} VideoBG={Office.Banner_Image} VideoURL={Office.Embed_Video_URL} />
            }
            {Office.Intro_Left_Content != null &&
              <HomeTextColumns ContentLeft={Office.Intro_Left_Content} ContentRight={Office.Intro_Right_Content} />
            }
            {Modules.map((Modules, i) => {
              return (
                <>
                  {Modules.Select_Module == "Team_Slider" &&
                    <TeamCarousel officearea={Office.Area} data={TeamSlider} />
                  }
                  {Modules.Select_Module == "Get_In_Touch" &&
                    <HomeContact data={TeamMember} Content={GlobalConfig.Get_In_Touch_Content} />
                  }
                  {Modules.Select_Module=='Office_detail_Map' &&
                    <Officemap data={Office} Latitude={Office.Latitude} Longitude={Office.Longitude} mapimage={Office.Map_Block_Image} mapvideo={Office.Map_Block_Video_Url} Areaguide={Office.Select_Areaguide} />
                  }
                </>
              )
            })}
            <HomeRowImgText reviewkey= {Office.Review_Key}/>
            
            <FeaturedProperty officeId={Office.CRM_Office_ID} area={Office.Area} showsale="true" showrent="true" Title={`Featured Properties in ${Office.Title}, London`} />
            <Valuation Title={GlobalConfig.Footer_Box_Title} Content={GlobalConfig.Footer_Box_Content} />
          </div>
      <div className="property-fixed-bar footer-cta d-lg-none">
        <Container>
            <Row className="justify-content-between">
                <Col md="12" lg="auto">
                    <div className="fixed-utils">
                        <a href={`tel:${Office.Phone_Number}`} className="btn dark d-lg-none">Call Us</a>
                        <Link href="/value-my-property/property-valuation/" className="btn">Valuation</Link>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
        </Layout>
      </>
    )
  }
}

export default OfficeTemplate

export const pageQuery = graphql`
query OfficeQuery($slug: String!) {
  strapiOffices(URL: {eq: $slug}) {
    strapiId
    Meta_Title
    Meta_Description
    Title   
    Review_Key 
    Area
    Address
    Phone_Number
    Email
    Latitude
    Longitude
    Intro_Left_Content
    Intro_Right_Content
    CRM_Office_ID
    URL
    Map_Block_Image {
      publicURL
    }
    Select_Areaguide {
      URL
      Title
      Embed_Video_URL
      Tile_Image {
        internal {
          description
        }
      }
    }
    Map_Block_Video_Url
    Tile_Image {
      internal {
        description
      }
    }
    Banner_Image {
      internal {
        description
      }
      childImageSharp {
        fluid(quality: 90, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imagetransforms {
      Tile_Image_Transforms
    }
    Embed_Video_URL
    Team_Members {
          id
          Designation
          Email
          Calendly_Link
          Image {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
          Name
          Phone
    }
    Slider_Team_Members {
          id
          Email
          URL
          Designation
          Name
          Embed_Video_URL
          imagetransforms {
            Image_Transforms
          }
          Image {
            internal {
              description
            }
          }
          Phone
    }
    Modules {
      Select_Module
    }
  }
  strapiGlobalConfig {
      Get_In_Touch_Content
      Footer_Box_Content
      Footer_Box_Title
      Popular_Search_Static_Common
      Popular_Search_Static_Rentals
      Popular_Search_Static_Sales
  }
}
`
